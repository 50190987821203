<template>
  <detail-layout-with-pic
    header="Our Commitment to you"
    addtional-pic-classes="w-full"
  >
    <template v-slot:text-col>
      <p>
        The physiotherapist who is treating you is registered with the HCPC and
        the Chartered Society of Physiotherapy.
      </p>
      <p>
        Every member of our team has been DBS Checked and has an ICO
        Registration number.
      </p>
      <p>
        The physiotherapist will have a strong commitment to developing their
        post graduate skills and will only be working within their field of
        expertise and in line with current recommendations of good practice.
      </p>
      <p>
        The physiotherapy assistant will only work under the direction of your
        physiotherapist and will not be asked to progress the treatment plan.
      </p>
      <p>
        During your assessment a full explanation of your problems will be
        discussed alongside a proposed treatment plan.
      </p>
      <p class="mb-0">
        There is no obligation for you to consent to further treatment.
      </p>
    </template>
    <template v-slot:pic-col>
      <table>
        <tr>
          <td>Diane Ormsby ICO Registration number:</td>
          <td>ZA380105</td>
        </tr>
        <tr>
          <td>Gemma Brown ICO Registration number:</td>
          <td>ZA380079</td>
        </tr>
        <tr>
          <td>Heather Ross ICO Registration number:</td>
          <td>ZA501049</td>
        </tr>
        <tr>
          <td>Alahna Cullen ICO Registration number:</td>
          <td>ZA8633920</td>
        </tr>
        <tr>
          <td>Adrian Yeoman ICO Registration number:</td>
          <td>ZA619834</td>
        </tr>
        <tr>
          <td>Claire Cook ICO Registration number:</td>
          <td>ZA580870</td>
        </tr>
        <tr>
          <td>Dorota Laska-Gaffney ICO Registration number:</td>
          <td>ZB267689</td>
        </tr>
      </table>
    </template>
    <div class="mb-8 sm:mb-14">
      <div class="w-full">
        <h2 class="mb-5">Letters and Reports</h2>
        <p>
          We are willing and happy to prepare letters and reports if required to
          support onward referrals or insurance requirements.
        </p>
      </div>
    </div>
    <div class="mb-8 sm:mb-14">
      <div class="w-full">
        <h2 class="mb-5">Consent and Confidentiality</h2>
        <ul>
          <li>We will need you to consent to treatment on every visit.</li>
          <li>
            You shall be informed on every visit of the treatment plan and
            goals.
          </li>
          <li>
            We shall always be happy to answer questions related to your
            assessment and treatment.
          </li>
          <li>
            When the treatment is complete we shall retrieve your file and keep
            your records in a secure locked location.
          </li>
          <li>
            We shall not share information with other agencies or professionals
            prior to your informed and documented consent.
          </li>
          <li>(Please refer to our privacy policy page.)</li>
        </ul>
      </div>
    </div>
    <div class="mb-8 sm:mb-14">
      <div class="w-full">
        <h2 class="mb-5">Concerns or Complaints.</h2>
        <p class="font-semibold">
          We strive to offer and provide a high quality physiotherapy service
          and always value your feedback.
        </p>
        <ul class="pb-5">
          <li>We aim to resolve any issues promptly and thoroughly</li>
          <li>To be supportive and resolve problems without blame</li>
          <li>To improve our service if indicated</li>
          <li>
            To provide information if the problem cannot be resolved via
            discussion with the practitioner
          </li>
        </ul>
        <p class="font-semibold">
          If however you have a concern or need to make a complaint:
        </p>
        <p>
          Please raise your concerns immediately with the therapist treating you
          verbally or in writing.
        </p>
        <p>
          It is wise to raise you concern immediately or within days; we shall
          keep documented evidence of your concern kept securely and with
          confidentiality.
        </p>
        <p>In many cases it will be possible to resolve the issue promptly.</p>
        <p>
          If you feel your concerns have not been adequately addressed and
          resolved please contact either:
        </p>
        <ul>
          <li>
            <span class="font-semibold"
              >The Chartered Society of Physiotherapy</span
            ><br />
            www.csp.org.uk<br />
            020 7306 6666 from 8:30-5pm Monday to Friday
          </li>
          <li>
            <span class="font-semibold"
              >The Health and Care Professions Council</span
            ><br />
            www.hcpc-uk.org<br />
            Freephone number: 0800 328 4218<br />
            Tel: +44 (0)20 7840 9814
          </li>
          <li>
            <span class="font-semibold">The Health Service Ombudsman</span
            ><br />
            www.ombudsman.org.uk<br />
            Customer Helpline on 0345 015 4033
          </li>
        </ul>
      </div>
    </div>
    <div class="mb-8 sm:mb-14">
      <div class="w-full">
        <h2 class="mb-5">Terms and Conditions</h2>
        <p>
          Balance Neuro Physiotherapy is a domicillary service currently based
          within the areas identified on our home page.
        </p>
        <p>
          If you happen to live outside of the area covered by your physio and
          would still like to consider us, then extra charges will be applied
          based on travel distance and time taken.
        </p>
        <p>
          The method of payment shall be agreed prior to commencement of
          treatments.
        </p>
        <p>
          Money shall usually be collected at the end of each treatment session
          (unless otherwise argreed), in either cash, cheque or BACS.
        </p>
        <p>
          Cheques should be made payable to the physiotherpaist who is treating
          you.
        </p>
        <p>
          No further treatments shall be booked if payment is not received at
          the agreed time.
        </p>
      </div>
    </div>
    <div class="mb-14 sm:mb-20">
      <div class="w-full">
        <h2 class="mb-5">Protecting Our Health and Safety</h2>
        <p>
          We reserve the right to leave a treatment session if somebody within
          the residence has smoked within the last hour, or if we feel at risk
          in any other way.
        </p>
        <p>Activities will only be undertaken if risk assessment permits.</p>
      </div>
    </div>
    <template v-slot:feature-cols>
      <div class="flex flex-wrap -m-4">
        <feature-3-cols
          :icon="['far', 'user']"
          title="Meet the team"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Meet The Team"
        />
        <feature-3-cols
          :icon="['far', 'heart']"
          title="Our commitment to you"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Our Commitment To You"
        />
        <feature-3-cols
          :icon="['far', 'map']"
          title="Areas covered"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Areas Covered"
        />
      </div>
    </template>
  </detail-layout-with-pic>
</template>

<style lang="scss" scoped>
table {
  @apply w-full rounded-lg shadow-table-shadow overflow-hidden;

  tr:last-child td {
    @apply border-b-0;
  }

  td {
    @apply bg-denim-50 border-b-2 border-royalblue-200 py-3 px-4;
  }
}
</style>

<script>
import DetailLayoutWithPic from "../layouts/DetailLayoutWithPic.vue";
import Feature3Cols from "../components/Feature3Cols.vue";

export default {
  name: "Our Commitment To You",
  components: {
    "detail-layout-with-pic": DetailLayoutWithPic,
    "feature-3-cols": Feature3Cols,
  },
};
</script>
